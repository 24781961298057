import React from 'react'
import './Home.css'
import logo from "./mohren_logo_new.svg";

const Home = ({ setStarted }) => {
    return (
        <div className="main-login-page">
            {/* <Header /> */}
            <div className="info-block">
                <img alt="logo" className="info-block-image" src={logo} />
                <h1>Willkommen in der Mohrenbräu App</h1>
                <h3 className="text-body mbt-30">
                    Werde Teil der Mohrenbräu Community und erhalte
                    Einblicke in die Welt von Vorarlberg‘s führender
                    Privatbrauerei. #dasvorarlbergerbier <br />
                </h3>
                <button
                    className="btn-facebooklogin mbt-30"
                    onClick={() => {
                        setStarted(true)
                    }}
                >
                    Jetzt starten
                </button>
            </div>
        </div>
    )
}

export default Home