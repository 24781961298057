import React, { useEffect, useRef } from 'react';

function Viewer() {
  const sceneRef = useRef(null);
  let url = 'https://ar.mohren.app/';

  if (process.env.NODE_ENV === 'development') {
    console.log("dev");
    url = 'https://a16f-94-199-169-209.ngrok-free.app/';
  }

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];
    sceneEl.addEventListener('renderstart', () => {
      arSystem.start(); // start AR 
    });
    return () => {
      arSystem.stop();
    }
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <a-scene ref={sceneRef} mindar-image={`imageTargetSrc: ${url}/targets.mind; autoStart: false; uiLoading: no; uiError: no; uiScanning: no;" color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights`} vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
        <a-assets>
          <a-asset-item id="avatarModel2" src={`${url}/flasche.glb`}></a-asset-item>
        </a-assets>

        <a-camera position="0 0 0" look-controls="enabled: false"></a-camera>

        <a-entity mindar-image-target="targetIndex: 1">
          <a-gltf-model rotation="90 0 0 " position="0 -0.25 0" scale="0.25 0.25 0.25" src="#avatarModel2" animation-mixer></a-gltf-model>
        </a-entity>
      </a-scene>
    </div>
  )
}

export default Viewer;