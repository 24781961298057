import React, { useState } from 'react';
import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import 'mind-ar/dist/mindar-image-aframe.prod.js';
import './App.css';
import MindARViewer from './mindar-viewer';
import Home from './Home/Home';
import backIcon from './back2.png';

function App() {
  const [started, setStarted] = useState(false);
  
  return (
    <div className="App">
      <div className="container">
        <MindARViewer />
        <video></video>
      </div>
      <div className='back-container'> <div className='circular-background' onClick={() => {
        setStarted(false)
      }}><img alt="back icon" src={backIcon} className='circular-image' /></div></div>
      {!started && <Home setStarted={setStarted} />}
    </div>
  );
}

export default App;
